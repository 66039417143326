
/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #F7E4EB;
}

mat-sidenav {
  margin: 16px;
  width: 220px;
  border-right: none;
  border-radius: 10px;
  padding: 16px;
  text-align: center;
}

.content {
  padding: 20px;
  background-color: white;
}

mat-sidenav-container {
  height: calc(100vh - 65px);
}
